.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 900px) {
  .header a {
    float: none;
    display: block;
    text-align: center;
  }
  .header-right {
    float: none;
  }
  #desktop-app {
    display: none;
  }
}
/* Header End */
.App {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0px 20px;
  margin-bottom: 6.25em;
}

.radio-station {
  background: #ddd;
  padding: 0.625em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.9375em 0.3125em;
  cursor: pointer;
}

.radio-station img {
  width: 17em;
}

.player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.station-name {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  background: white;
}

.category {
  color: black;
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  font-size: 1.2rem;
}
